<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Emails
        </h1>
    </div>
    <div class="">
        <button @click="getItems">Fetch</button>
    </div>
    <div class="">
        <SelectInput v-model="email_account" label="Email Account" :options="[{'label':'Support@OnlineAda.Com','value': 'support'},{'label':'Service@OnlineAda.Com','value': 'service'}]"></SelectInput>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="emails.length"  class="">
        <TableTemplate :candelete="false" canview="/emails/" :columns="columns" :items="emails"></TableTemplate>
    </div>
</div>
</template>

<script>
import Axios from 'axios'
import moment from 'moment'

export default {
    name: 'EmailsList',
    mixins: [],
    data: () => ({
        search: '',
        email_account: 'support',
        limit: 10,
        action: '/emails',
        searchParams: {
            'model': 'Email',
            'fields': ['id'],
            'strict' : false,
        },
        columns:[
            {
                id:'msg_num',
                label:'Message Number',
            },
            {
                id:'subject',
                label:'Subject',
            },
            {
                id:'date',
                label:'Date',
            },
            {
                id:'from',
                label:'From',
                custom:'ParseFrom',
            },
        ],
        emails: [],
        replies: [],
        count: 0,
    }),
    computed: {
    },
    props: [],
    methods: {
        maybeSearch() {},
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        getItems() {
            let that = this
            Axios.post('/emails',{
                email_account: this.email_account,
                limit: this.limit,
                offset: this.page,
            }).then(re=>{
                that.emails = re.data.emails.main
                that.replies = re.data.emails.replies
                that.count = re.data.count   
            }).catch(err=>{
                console.log(err)
            })
        },
        ParseFrom(item) {
            let from = ''
            let first = true
            for(let i in item.from) {
                if(!first) {
                   from += ' ,' 
                }
                from += item.from[i].mailbox + '@' + item.from[i].host
                if(first) first = false
            }
            return from
        },
    },
    watch: {
        email_account: {
            handler() {
                this.getItems()
            },
            deep:true,
        },
    },
    created() {
        this.getItems()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
